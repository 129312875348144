<template>
  <v-card flat class="ma-0">
    <v-card-text class="pa-5">
      <div class="d-md-flex align-items-center">
        <div>
          <h3 class="title font-weight-regular">{{ $t('Clinic.ClinicsNetwork') }}</h3>
          <h6 class="subtitle-2 font-weight-light">
            {{ $t('Clinic.DisplayAllClinics') }}
          </h6>
        </div>
      </div>
      <div class="mt-4" style="height: 300px">
        <App
          :countryData="countryData"
          defaultCountryFillColor="rgb(201, 214, 222)"
          highColor="rgb(201, 214, 222)"
          lowColor="rgb(201, 214, 222)"
          countryStrokeColor="transparent"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import App from "vue-world-map";

export default {
  name: "TheCurrentVisitors",
  data: () => ({
    countryData: {
      US: 100,
      IN: 200,
      VN: "red",
    },
  }),
  components: {
    App,
  },
};
</script>